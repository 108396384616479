import axios from 'axios';
import Ui from './class/Ui';

/**
 *
 * @param el
 * @returns {HTMLElement}
 */
window.oid = function (el) {
    return document.getElementById(el);
}

window.request = function (url, method, options = {}, headers = {}) {

    const defaultHeaders = {
        "Content-Type": "application/json",
        "Data-Source": "ajax"
    };

    const defaultOptions = {
        url: url,
        method: method,
        headers: {...defaultHeaders, ...headers}
    };

    return axios.request({...defaultOptions, ...options});
}

/**
 *
 * @param url
 * @param method
 * @param data
 * @param reauthAuto
 * @param xhrOptions
 * @returns {Promise<any>}
 */
window.ajx = function (url, method = "GET", data = null, reauthAuto = true, xhrOptions = {}) {
    return new Promise(function (resolve, reject) {
        if (typeof (method) === "undefined") {
            method = "GET";
        }
        if (typeof (data) === "undefined") {
            data = "";
        }

        if (!url.includes("source=")) {
            url += (url.includes('?') ? '&' : '?') + "source=ajax";
        }

        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    if (xhr.responseType === "arraybuffer") {
                        resolve(xhr.response, xhr);
                    } else {
                        resolve(xhr.responseText, xhr);
                    }
                } else {
                    if (xhr.status === 401 && reauthAuto) {
                        // Tentative de reconnexion (via cookies)
                        return ajx(baseurl + 'services/reauth').then((response) => {
                            var resp = JSON.parse(response);
                            if (resp.hasOwnProperty('status') && resp.status === 'connected') {
                                // Nouvelle tentative d'appel
                                return ajx(url, method, data, false);
                            } else {
                                Ui.alert('Vous devez être connecté pour accéder à cette page.', function (e) {
                                    // Sinon on renvoie sur la page de connexion
                                    window.location.href = baseurl + 'login?redirect=' + window.location.href;
                                }, 'Se reconnecter');
                            }
                        });
                    }
                    else reject(xhr.responseType === "arraybuffer" ? xhr.response : xhr.responseText, xhr);
                }
            }
        };

        if (xhrOptions?.responseType) {
            xhr.responseType = xhrOptions.responseType;
        }

        xhr.open(method, url, true);
        if ((method === "POST" || method === "DELETE") && !(data instanceof FormData)) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            if (typeof data === 'object') {
                var form_data = new FormData();
                for (let k in data) {
                    if (data.hasOwnProperty(k)) {
                        if (typeof data[k] === 'object') {
                            data[k] = JSON.stringify(data[k]);
                        }
                        form_data.append(k, data[k]);
                    }
                }
                data = form_data;
            } else {
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            }
        }
        xhr.send(data);
    });
}

/**
 * Foreach loop
 *
 * @param elements classname or dom array
 * @param callable
 * @param offset
 * @param container
 */
window.foreach = function (elements, callable, offset = 0, container = document) {
    if (typeof elements === 'string') {
        elements = container.getElementsByClassName(elements);
    }
    let i = 0;
    if (typeof offset !== 'undefined') {
        i = offset;
    }
    while (i < elements.length) {
        if (elements.hasOwnProperty(i)) {
            callable(elements[i], i);
        }
        i++;
    }
}

/**
 *
 * @param context
 * @param properties
 * @returns {*}
 */
window.addProperty = function (context, properties) {
    for (let key in properties) {
        if (properties.hasOwnProperty(key)) {
            context[key] = properties[key];
        }
    }
    return context;
}

/**
 *
 * @param e
 * @returns {*}
 */
window.getKeyCode = function (e) {
    if (e.key !== undefined) {
        return e.key;
    } else if (e.keyCode !== undefined) {
        return e.keyCode;
    }
}

/**
 *
 * @param e
 * @returns {boolean}
 */
window.pressEnter = function (e) {
    const keyCode = getKeyCode(e);
    return keyCode === 'Enter' || keyCode === 13;
}

/**
 *
 * @param e
 * @returns {boolean}
 */
window.pressEchap = function (e) {
    const keyCode = getKeyCode(e);
    return keyCode === 'Escape' || keyCode === 27;
}

/**
 *
 * @param form
 * @param callback
 * @param url
 * @param submitButton
 */
window.setFormPostListener = function (form, callback, url, submitButton) {
    form.addEventListener('submit', function (e) {
        e.preventDefault();
        if (form.getAttribute('data-confirm')) {
            Ui.confirm(form.getAttribute('data-confirm'), () => {
                return sendFormPostRequest(form, callback, url, submitButton);
            });
        } else {
            return sendFormPostRequest(form, callback, url, submitButton);
        }
    });
}

window.sendFormPostRequest = function (form, callback = null, url = null, submitButton = null, xhrOptions = {}, throwError = false) {

    if (!submitButton) {
        submitButton = form.querySelector('button[type="submit"]');
    }

    if (submitButton) {
        submitButton.classList.add('loading');
    }

    var data = new FormData(form);
    if (form.enctype !== "multipart/form-data") {
        data = new URLSearchParams(data).toString();
    }

    if (!url) {
        url = form.action;
    }

    // Message si on quitte la page
    window.addEventListener('beforeunload', warnBeforeLeave);

    return ajx(url, 'POST', data, true, xhrOptions)
        .then(resp => {
            window.removeEventListener("beforeunload", warnBeforeLeave);
            if (callback) {
                callback(resp);
            }
            return resp;
        })
        .catch(response => {
            window.removeEventListener("beforeunload", warnBeforeLeave);
            var resp = response;
            if (response.constructor === ArrayBuffer) {
                resp = String.fromCharCode.apply(null, new Uint8Array(resp));
            }
            handleJsonResp(resp);
            if (throwError) {
                throw new Error(response);
            }
            return response;
        })
        .finally(() => {
            if (submitButton) {
                submitButton.classList.remove('loading');
            }
        });
}

window.warnBeforeLeave = function (e) {
    e.preventDefault();
    e.returnValue = '';
}

/**
 *
 * @param el
 * @returns {*}
 */
window.getSelectedOption = function (el) {
    return el.options[el.selectedIndex];
}

/**
 *
 * @param el
 * @returns {*}
 */
window.getSelectedOptionValue = function (el) {
    if (!el) return null;
    if (el.type === "SELECT") {
        return getSelectedOption(el).value;
    }
    return el.value;
}

/**
 * Met à jour l'option sélectionnée d'un select
 *
 * @param e
 * @param optionValue
 */
window.setSelectedOption = function setSelectedOption(e, optionValue) {
    for (let i in e.options) {
        if (e.options[i].value == optionValue) e.selectedIndex = i;
    }
}

/**
 *
 * @param element
 * @param action
 * @param event
 * @param preventDefault
 */
window.bind = function (element, action, event = 'click', preventDefault = true) {
    if (typeof element === 'string') {
        element = oid(element);
    }
    if (element) {
        element.addEventListener(event, function (e) {
            if (preventDefault) e.preventDefault();
            if (e.target.getAttribute('disabled') === null && !e.target.classList.contains("disabled")) {
                action(e, this);
            }
        });
    }
}

window.slugify = function (text, trim = true) {
    if (trim) {
        return text.toString().toLowerCase()
            .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/--+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    } else {
        return text.toString().toLowerCase()
            .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/--+/g, '-');
    }
}

/**
 *
 * @param resp JSON
 * @param defaultSuccessMessage
 * @param defaultErrorMessage
 */
window.handleJsonResp = function (resp, defaultSuccessMessage = 'Succès', defaultErrorMessage = 'Erreur') {
    if (!resp) return;
    try {
        var data = JSON.parse(resp);
        if (!data) return;
        if (data && data.hasOwnProperty('status')) {
            if (data.status === 'success') {
                if (data.hasOwnProperty('message')) {
                    Ui.notif(data.message, 'success');
                } else {
                    Ui.notif(defaultSuccessMessage, 'success');
                }
            } else {
                if (data.hasOwnProperty('message')) {
                    Ui.notif(data.message, 'error');
                } else {
                    Ui.notif(defaultErrorMessage, 'error');
                }
            }
        } else {
            if (data.hasOwnProperty('error')) {
                if (data.error.hasOwnProperty('message')) {
                    Ui.notif(data.error.message, 'error');
                } else {
                    Ui.notif(defaultErrorMessage, 'error');
                }
            }
        }
    } catch (e) {
        console.error(e, resp);
    }

    return data;
}

window.copy = function (input, context = false) {
    if (typeof input === "string") {
        navigator.clipboard.writeText(input).then(() => {
            if (context) {
                popover(context, "small inline nowrap tr");
            } else {
                Ui.notif('Le texte a été copié dans le presse-papier', 'success');
            }
        });
    } else {
        input.select();
        if (document.execCommand("copy")) {
            if (context) {
                popover(context, "small inline nowrap tr");
            } else {
                Ui.notif('Le texte a été copié dans le presse-papier', 'success');
            }
        }
    }
}

window.popover = function (context, additionalClassName = '') {
    let span = document.createElement("span");
    span.innerHTML = "<i class='ic icon-check'></i> Le texte a été copié";
    span.className = "popover " + additionalClassName;
    context.append(span)

    setTimeout(() => {
        span.classList.add('active');
        setTimeout(() => {
            span.classList.remove('active');
            setTimeout(() => {
                span.remove();
            }, 200);
        }, 3000);
    }, 200);
}

window.toggleClass = function (el, className, bool) {
    if (bool) el.classList.add(className)
    else el.classList.remove(className)
}

window.utf8_to_b64 = function (str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

window.b64_to_utf8 = function (str) {
    return decodeURIComponent(escape(window.atob(str)));
}

window.setCookie = function (name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

window.getCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

window.eraseCookie = function (name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

window.loadScript = function (src, callback) {
    var script = document.createElement('script');
    script.onload = function () {
        callback()
    };
    script.src = src;
    document.head.appendChild(script);
}

window.round = function (num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

window.uniqid = function (length = 12) {
    return Math.random().toString(36).substr(2, length);
}

window.isDescendant = function (parent, child) {
    var node = child.parentNode;
    while (node != null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

window.applyEvent = function (target, event = "change", value = null) {
    if (value) {
        target.value = value;
    }
    setTimeout(() => {
        target.dispatchEvent(new Event(event));
    }, 100);
}


/**
 * Récupère la liste des distributeurs
 *
 * @return {Promise<any>}
 */
window.fetchOrganizations = function () {
    return fetch(baseurl + 'services/organizations?output=json').then((resp) => {
        return resp.json();
    })
}

/**
 * Récupère la liste des produits du distributeur passé en paramètre
 *
 * @param organization_id
 * @param includes_deleted
 * @return {Promise<any>}
 */
window.fetchProductFromOrganization = function (organization_id, includes_deleted = true) {
    return fetch(baseurl + 'services/organizations/' + organization_id + '/products?output=json&inc_deleted=' + (includes_deleted ? 1 : 0), {
        method: 'GET',
    }).then((resp) => {
        return resp.json();
    })
}

/**
 * Récupère la liste des grilles du produit passé en paramètre
 *
 * @param product_id
 * @return {Promise<any>}
 */
window.fetchGridFromProduct = function (product_id) {
    return fetch(baseurl + 'services/products/' + product_id + '/grids?output=json',{
        method: 'GET',
    }).then((resp) => {
        return resp.json();
    })
}